<template>
  <div v-if="!showLoading" class="container" style="min-height: auto;">
    <Progress :quizIndex="quizIndex" :maxQuizLength="quiz.length" />
    <transition-group name="slide" tag="div" class="div-slider" v-for="(q, i) in quiz" :key="i">
      <header
        v-if="i === quizIndex && quizTransition"
        :key="`header-${i}`"
        class="quiz-title"
        v-html="q.q"
      ></header>
      <div v-if="i === quizIndex && quizTransition" class="cover" :key="`cover-${i}`">
        <img :src="`/quiz/${i + 1}번질문.png`" :alt="`${i + 1}번질문.png`" class="test-img" />
        <button
          v-for="(a, i) in q.a"
          class="quiz"
          :key="`answer-${i}`"
          v-html="a.txt"
          @click="getScore(a.type)"
        ></button>
      </div>
    </transition-group>
  </div>
  <div v-else class="container loading">
    <img
      v-show="loadingImgIndex === i"
      v-for="(img, i) in loadingImg"
      class="loading-img"
      :src="`/image/${i + 1}.png`"
      :alt="img"
      :key="img"
    />
    <div class="loading-text">로딩 중{{loadingText}}</div>
  </div>
</template>
<script>
import Progress from "@/components/Progress";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Test",
  components: {
    Progress
  },
  data() {
    return {
      quizIndex: 0,
      quizTransition: true,
      showLoading: false,
      loadingImgIndex: 0,
      loadingText: '.',
      loadingImg: ["나무젓가락", "고추", "대파", "계란", "냄비"],
      result: {
        e: 0,
        i: 0,
        s: 0,
        n: 0,
        t: 0,
        f: 0,
        j: 0,
        p: 0
      },
      quiz: [
        {
          q: "마트에서 신제품 시식코너가<br/>진행 중일 때 나는",
          a: [
            {
              txt: "뭘 고민해?<br/>너스레를 떨며 시식을 한다",
              type: "e"
            },
            {
              txt: "아.. 어쩌지..<br/>먹으면 사야 되는 거 아닌가 고민한다..",
              type: "i"
            }
          ]
        },
        {
          q: "버스에서 벨을 눌렀는데<br/> 문을 안 열어주실 때 나는",
          a: [
            {
              txt: "소리치며 내려달라 한다.",
              type: "e"
            },
            {
              txt: "조용히 다음 정거장을 기다린다.",
              type: "i"
            }
          ]
        },
        {
          q: "비대면 줌 회의를 할 때 나는",
          a: [
            {
              txt: "주로 대화를 주도하고<br/>이끌어 나간다.",
              type: "e"
            },
            {
              txt: "주로 상대방의 얘기를 듣는다.",
              type: "i"
            }
          ]
        },
        {
          q: "새로 나온 ‘신라면 볶음면’을 먹고 친구에게 소개할 때 나는",
          a: [
            {
              txt: "사실 위주로 상세히 알려준다.",
              type: "s"
            },
            {
              txt: "나의 느낌을 기반으로 설명해 준다.",
              type: "n"
            }
          ]
        },
        {
          q: "‘멍 때리기’의 정의를 생각할 때 나는",
          a: [
            {
              txt: "아무 생각 없는 것",
              type: "s"
            },
            {
              txt: "깊은 생각에 잠기는 것",
              type: "n"
            }
          ]
        },
        {
          q: "배달음식을 시켜 먹을 때 나는",
          a: [
            {
              txt: "오? 이거 맛있어 보이네,<br/>바로 주문을 한다.",
              type: "s"
            },
            {
              txt:
                "(30곳을 자료조사 후) 리뷰 이벤트를<br/>적으면 치즈볼을 준다고? 그럼 여기네.",
              type: "n"
            }
          ]
        },
        {
          q: '"너 그렇게 행동하면 아무도 옆에 없을걸?"<br/>라는 말을 들은 나는',
          a: [
            {
              txt: "크게 상처받고 속상해한다.",
              type: "f"
            },
            {
              txt: "“어쩌라고”.",
              type: "t"
            }
          ]
        },
        {
          q: "둘 중 한 사람과 알바를 해야 한다면 나는",
          a: [
            {
              txt: "일 잘하는데 성격이 별로인 사람",
              type: "t"
            },
            {
              txt: "일 못하는데 성격 좋은 사람",
              type: "f"
            }
          ]
        },
        {
          q: "열심히 끓인 라면 맛이 이상할 때 나는",
          a: [
            {
              txt: "뭐가 잘못됐는지 곰곰이 생각해본다.",
              type: "t"
            },
            {
              txt: "일단 속상해한다.",
              type: "f"
            }
          ]
        },
        {
          q:
            "SNS에서 진짜 맛있어 보이는<br/>라면 꿀 조합 레시피를 발견했을 때 나는",
          a: [
            {
              txt: "필요할 수 있으니 <br/>저장하거나 캡처해 둔다.",
              type: "j"
            },
            {
              txt: "나중에 필요할 때 찾자.<br/>뭐~ 일단 그냥 넘긴다.",
              type: "p"
            }
          ]
        },
        {
          q: "새로운 요리를 할 때 나는",
          a: [
            {
              txt: "레시피 순서 그대로 따라 한다.",
              type: "j"
            },
            {
              txt:
                "순서는 내 마음! 어차피 결과물은<br/>다 똑같으니 자유롭게 만든다.",
              type: "p"
            }
          ]
        },
        {
          q: "내일 썸 타는 연인이<br/>라면 먹으러 온다고 했을 때 나는",
          a: [
            {
              txt: "썸녀(남)가 오기 직전에 급하게 치운다",
              type: "p"
            },
            {
              txt: "쓰레기 버리기부터<br/>화장실 청소까지 전부 한다.",
              type: "j"
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.quizIndex = 0;
    this.result = {
      e: 0,
      i: 0,
      s: 0,
      n: 0,
      t: 0,
      f: 0,
      j: 0,
      p: 0
    };
  },
  methods: {
    getScore(type) {
      this.result[type] += 1;
      this.quizIndex += 1;
      this.quizTransition = false;
      setTimeout(() => {
        this.quizTransition = true;
      }, 200);
      if (this.quizIndex >= this.quiz.length) {
        const increment = firebase.firestore.FieldValue.increment(1);
        const docRef = this.$firestore.collection("user").doc("rbti");
        docRef.update({ finishCount: increment });
        this.showLoading = true;
        document.body.style.backgroundColor = '#ff8700';
        setInterval(() => {
          if (this.loadingImgIndex >= this.loadingImg.length - 1) {
            this.loadingImgIndex = 0;
            this.loadingText += '.';
          } else {
            this.loadingImgIndex += 1;
          }
        }, 300);
        setTimeout(() => {
          this.$router.push({
            name: "Result",
            params: {
              id: this.getResult()
            }
          });
        }, 3000);
      }
    },
    getResult() {
      const result = [
        this.result.e > this.result.i ? "e" : "i",
        this.result.s > this.result.n ? "s" : "n",
        this.result.f > this.result.t ? "f" : "t",
        this.result.j > this.result.p ? "j" : "p"
      ];
      return result.join("");
    }
  }
};
</script>