<template lang="html">
    <div class="progress-bar">
      <div class="fill" :style="{width: `${(quizIndex + 1)/maxQuizLength * 100}%`}"></div>
    </div>
</template>
<script>
export default {
  props: {
    quizIndex: {
      type: Number,
      default() {
        return 0;
      }
    },
    maxQuizLength: {
      type: Number,
      default() {
        return 12;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.progress-bar {
  width: 312px;
  background: #ffcbb2;
  height: 4px;
  display: flex;
  margin: 43px auto 20px;
  .fill {
    background-color: #ff4c48;
  }
}
</style>